const initialState = { firstName: "", email: "", lastName: "", savings: "" };

function profilAnnapurna(state = initialState, action) {
  const nextState = { ...state };

  switch (action.type) {
    case "setProfilAnnapurna":
      nextState.firstName = action.content.first_name;
      nextState.email = action.content.email;
      nextState.lastName = action.content.last_name;
      nextState.savings = action.content.savings;
      return nextState;
    default:
      return state;
  }
}

export default profilAnnapurna;
