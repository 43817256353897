const initialState = {
  identity_documents: { status: 0 },
  bank_document: { status: 0 },
  contact_phone: { status: false },
  currentDocBank: false,
  currentDocIdentity1: false,
  currentDocIdentity2: false,
};

function documentsReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setIdentityDocuments":
      nextState = { ...state };
      nextState.identity_documents = action.content;
      return nextState;
    case "setBankDocuments":
      nextState = { ...state };
      nextState.bank_document = action.content;
      return nextState;
    case "setContactPhone":
      nextState = { ...state };
      nextState.contact_phone = action.content;
      return nextState;
    case "setCurrentDocBank":
      nextState = { ...state };
      nextState.currentDocBank = action.content;
      return nextState;
    case "setCurrentDocIdentity1":
      nextState = { ...state };
      nextState.currentDocIdentity1 = action.content;
      return nextState;
    case "setCurrentDocIdentity2":
      nextState = { ...state };
      nextState.currentDocIdentity2 = action.content;
      return nextState;
    default:
      return state;
  }
}

export default documentsReducer;
