const initialState = {
  mobile: {
    provider: "",
    mobileData: 0,
    mobileNumber: "",
    price: 0,
    message: "",
  },
  gas: {
    provider: "",
    address: "",
    class: "",
    zone: "",
    conso: 0,
    price: 0,
    message: "",
  },
  internet: { provider: "", address: "", price: 0, message: "" },
  elec: {
    provider: "",
    address: "",
    type: "base",
    base: 0,
    hp: 0,
    hc: 0,
    price: 0,
    kva: 6,
    message: "",
  },
};

function modifyFormAnnapurna(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "annapurnaMobileForm":
      nextState = { ...state };
      nextState.mobile = Object.assign(nextState.mobile, action.content);
      return nextState;
    case "annapurnaGasForm":
      nextState = { ...state };
      nextState.gas = Object.assign(nextState.gas, action.content);
      return nextState;
    case "annapurnaInternetForm":
      nextState = { ...state };
      nextState.internet = Object.assign(nextState.internet, action.content);
      return nextState;
    case "annapurnaElecForm":
      nextState = { ...state };
      nextState.elec = Object.assign(nextState.elec, action.content);
      return nextState;
    case "cleanFunnel":
      return initialState;
    default:
      return state;
  }
}

export default modifyFormAnnapurna;
