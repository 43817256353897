const initialState = {
  service: "",
  bat: "",
  heat: "",
  email: "",
  name: "",
  fibre: "",
  age: "",
  listPreview: [],
  listId: [],
  address: "",
  provider: "",
  price: null,
  surface: null,
  mobileData: "",
  id: "",
  mode: "noConnected",
  receive: false,
  quick: false,
  servicePk: null,
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function infosFunnel(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "initialiseSangalila":
      return {
        service: "",
        bat: "",
        heat: "",
        email: "",
        name: "",
        fibre: "",
        age: "",
        listPreview: [],
        listId: [],
        address: "",
        provider: "",
        price: null,
        surface: null,
        mobileData: "",
        id: "",
        mode: "noConnected",
        receive: false,
        quick: false,
        servicePk: null,
      };

    case "setPostalCodeSangalila":
      nextState = { ...state };
      nextState.postalCode = action.content;
      return nextState;

    case "setServiceSangalila":
      nextState = { ...state };
      nextState.service = action.content;
      return nextState;

    case "setBatSangalila":
      nextState = { ...state };
      nextState.bat = action.content;
      if (action.content === "appart") {
        nextState.surface = 50;
      } else {
        nextState.surface = 120;
      }
      return nextState;

    case "setSurfaceSangalila":
      nextState = { ...state };
      nextState.surface = action.content;
      return nextState;

    case "setHeatSangalila":
      nextState = { ...state };
      nextState.heat = action.content;
      return nextState;

    case "setEmailSangalila":
      nextState = { ...state };
      nextState.email = action.content;
      return nextState;

    case "setQuickSangalila":
      nextState = { ...state };
      nextState.quick = action.content;
      return nextState;

    case "setNameSangalila":
      nextState = { ...state };
      nextState.name = capitalizeFirstLetter(action.content);
      return nextState;

    case "setIdSangalila":
      nextState = { ...state };
      nextState.id = action.content;
      return nextState;

    case "setModeSangalila":
      nextState = { ...state };
      nextState.mode = action.content;
      return nextState;

    case "setReceiveSangalila":
      nextState = { ...state };
      nextState.receive = action.content;
      return nextState;

    case "setAgeSangalila":
      nextState = { ...state };
      nextState.age = action.content;
      if (action.content === "less") {
        nextState.price = 30;
      } else {
        nextState.price = 50;
      }
      return nextState;

    case "setFibreSangalila":
      nextState = { ...state };
      nextState.fibre = action.content;
      return nextState;

    case "setAddressSangalila":
      nextState = { ...state };
      nextState.address = action.content;
      return nextState;

    case "setServicePkSangalila":
      nextState = { ...state };
      nextState.servicePk = action.content;
      return nextState;

    case "setProviderSangalila":
      nextState = { ...state };
      nextState.provider = action.content;
      return nextState;

    case "setPriceSangalila":
      nextState = { ...state };
      nextState.price = action.content;
      return nextState;

    case "setDataSangalila":
      nextState = { ...state };
      nextState.mobileData = action.content;
      if (action.content === "less10") {
        if (nextState.age === "less") {
          nextState.price = 15;
        } else {
          nextState.price = 20;
        }
      } else {
        if (nextState.age === "less") {
          nextState.price = 20;
        } else {
          nextState.price = 30;
        }
      }
      return nextState;

    case "addListPreviewSangalilla":
      nextState = { ...state };
      nextState.listPreview.push(action.content);
      return nextState;

    case "removeListPreviewSangalilla":
      nextState = { ...state };
      nextState.listPreview = nextState.listPreview.filter(
        (el) => el.idFix !== action.content
      );
      nextState.listId = nextState.listPreview
        .filter((el) => el.isSend === true)
        .map((elem) => elem.id);
      return nextState;

    case "addIdSangalilla":
      nextState = { ...state };
      var toChange = nextState.listPreview.filter(
        (el) => el.idFix === action.content.idFix
      )[0];
      toChange.id = action.content.id;
      toChange.isSend = true;
      nextState.listPreview
        .filter((el) => el.idFix !== action.content.idFix)
        .push(toChange);
      nextState.listId = nextState.listPreview
        .filter((el) => el.isSend === true)
        .map((elem) => elem.id);
      return nextState;

    case "addErrorSangalilla":
      nextState = { ...state };
      var toChange2 = nextState.listPreview.filter(
        (el) => el.idFix === action.content.idFix
      )[0];
      toChange2.isError = true;
      nextState.listPreview
        .filter((el) => el.idFix !== action.content.idFix)
        .push(toChange2);
      return nextState;

    default:
      return state;
  }
}

export default infosFunnel;
