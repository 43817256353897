const initialState = {
  error: false,
  errorType: null,
  services: true,
  profil: true,
  documents: true,
  needRefresh: true,
  swipedRight: false,
  swipedLeft: false,
  slide: "right",
};

function loadingAnnapurna(state = initialState, action) {
  const nextState = { ...state };
  switch (action.type) {
    case "chooseAnnapurnaSlide":
      nextState.slide = action.content;
      return nextState;
    case "annapurnaSwipedRight":
      nextState.swipedRight = action.content;
      return nextState;
    case "annapurnaSwipedLeft":
      nextState.swipedLeft = action.content;
      return nextState;
    case "setLoadingAnnapurnaServices":
      nextState.services = action.content;
      return nextState;
    case "setLoadingAnnapurnaProfil":
      nextState.profil = action.content;
      return nextState;
    case "setLoadingAnnapurnaDocuments":
      nextState.documents = action.content;
      return nextState;
    case "needAnnapurnaRefresh":
      nextState.needRefresh = action.content;
      return nextState;
    case "setAnnapurnaError":
      nextState.error = action.content;
      return nextState;
    case "setAnnapurnaTypeError":
      nextState.errorType = action.content;
      return nextState;
    default:
      return state;
  }
}

export default loadingAnnapurna;
