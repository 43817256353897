const initialState = { progress: 0, currentPage: "page1", service: null };

function funnelGeneral(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "setProgress":
      nextState = { progress: action.content, currentPage: state.currentPage };
      return nextState;
    case "setCurrentPage":
      nextState = { progress: state.progress, currentPage: action.content };
      return nextState;
    default:
      return state;
  }
}

export default funnelGeneral;
