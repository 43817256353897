const initialState = {
  listPreview: [],
  listId: [],
};

function dropReducer(state = initialState, action) {
  let nextState = { ...state };
  switch (action.type) {
    case "addListPreviewJannu":
      nextState = { ...state };
      nextState.listPreview.push(action.content);
      return nextState;
    case "removeListPreviewJannu":
      nextState = { ...state };
      nextState.listPreview = nextState.listPreview.filter(
        (el) => el.idFix !== action.content
      );
      nextState.listId = nextState.listPreview
        .filter((el) => el.isSend === true)
        .map((elem) => elem.id);
      return nextState;
    case "addIdJannu":
      nextState = { ...state };
      var toChange = nextState.listPreview.filter(
        (el) => el.idFix === action.content.idFix
      )[0];
      toChange.id = action.content.id;
      toChange.isSend = true;
      nextState.listPreview
        .filter((el) => el.idFix !== action.content.idFix)
        .push(toChange);
      nextState.listId = nextState.listPreview
        .filter((el) => el.isSend === true)
        .map((elem) => elem.id);
      return nextState;
    case "addErrorJannu":
      nextState = { ...state };
      var toChange2 = nextState.listPreview.filter(
        (el) => el.idFix === action.content.idFix
      )[0];
      toChange2.isError = true;
      nextState.listPreview
        .filter((el) => el.idFix !== action.content.idFix)
        .push(toChange2);
      return nextState;
    case "cleanListsJannu":
      nextState = { ...state };
      nextState.listPreview = [];
      nextState.listId = [];
      return nextState;
    default:
      return state;
  }
}

export default dropReducer;
