const initialState = {
  list: [],
  active: 0,
  object: {},
};

function servicesReducer(state = initialState, action) {
  let nextState;
  let servicesActifs = 0;
  let services = {};
  let listKey = [];
  switch (action.type) {
    case "setServicesAnnapurna":
      if (action.content.length > 0) {
        for (const i in action.content) {
          let key = action.content[i].service.pk;
          listKey.push(key);
          let value = action.content[i];
          let objectService = { [key]: value };
          services = Object.assign(services, objectService);
          if (action.content[i].status_display === 1) {
            servicesActifs += 1;
          }
        }
      }

      nextState = {
        object: services,
        active: servicesActifs,
        list: action.content,
      };
      return nextState;
    default:
      return state;
  }
}

export default servicesReducer;
