import TagManager from "react-gtm-module";

const importGTM = () => {
  const tagManagerArgs =
    process.env.NEXT_PUBLIC_GTM_PREVIEW !== "false"
      ? {
          gtmId: process.env.NEXT_PUBLIC_GTM_ID,
          auth: process.env.NEXT_PUBLIC_GTM_AUTH,
          preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
        }
      : {
          gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        };
  TagManager.initialize(tagManagerArgs);
};

export const setGTM = () => {
  setTimeout(() => {
    importGTM();
  }, 500);
  return null;
};
