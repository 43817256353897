const initialState = {
  startForm: false,
  listBank: [],
  listIdentity: [],
  rio: "",
  pto: "",
  tel: "",
  comment: "",
  command: null,
  model: null,
  side: false,
  service: "",
  listPreview: [],
  listId: [],
  active: "infos",
  advance: 0,
};

function formReducer(state = initialState, action) {
  let nextState = { ...state };
  switch (action.type) {
    case "setAdvanceAnnapurna":
      nextState.advance = action.content;
      return nextState;
    case "setListBank":
      nextState.listBank = action.content;
      return nextState;
    case "setListBankItem":
      nextState.listBank = [...nextState.listBank, action.content];
      return nextState;
    case "setServiceAnnapurna":
      nextState.service = action.content;
      return nextState;
    case "setSideAnnapurna":
      nextState.side = action.content;
      return nextState;
    case "setListIdentity":
      nextState.listIdentity = action.content;
      return nextState;
    case "setListIdentityItem":
      nextState.listIdentity = [...nextState.listIdentity, action.content];
      return nextState;
    case "startForm":
      nextState.startForm = true;
      return nextState;
    case "setRio":
      nextState.rio = action.content;
      return nextState;
    case "setPto":
      nextState.pto = action.content;
      return nextState;
    case "setModel":
      nextState.model = action.content;
      return nextState;
    case "setTel":
      nextState.tel = action.content;
      return nextState;
    case "setComment":
      nextState.comment = action.content;
      return nextState;
    case "setCommandId":
      nextState.command = action.content;
      return nextState;
    case "cleanFunnel":
      nextState = initialState;
      nextState.command = state.command;
      return nextState;
    case "addListPreviewAnnapurna":
      nextState = { ...state };
      nextState.listPreview.push(action.content);
      return nextState;
    case "removeListPreviewAnnapurna":
      nextState = { ...state };
      nextState.listPreview = nextState.listPreview.filter(
        (el) => el.idFix !== action.content
      );
      nextState.listId = nextState.listPreview
        .filter((el) => el.isSend === true)
        .map((elem) => elem.id);
      return nextState;
    case "addIdAnnapurna":
      nextState = { ...state };
      var toChange = nextState.listPreview.filter(
        (el) => el.idFix === action.content.idFix
      )[0];
      toChange.id = action.content.id;
      toChange.isSend = true;
      nextState.listPreview
        .filter((el) => el.idFix !== action.content.idFix)
        .push(toChange);
      nextState.listId = nextState.listPreview
        .filter((el) => el.isSend === true)
        .map((elem) => elem.id);
      return nextState;
    case "addErrorAnnapurna":
      nextState = { ...state };
      var toChange2 = nextState.listPreview.filter(
        (el) => el.idFix === action.content.idFix
      )[0];
      toChange2.isError = true;
      nextState.listPreview
        .filter((el) => el.idFix !== action.content.idFix)
        .push(toChange2);
      return nextState;
    case "cleanListsAnnapurna":
      nextState = { ...state };
      nextState.listPreview = [];
      nextState.listId = [];
      return nextState;
    case "setActiveSide":
      nextState = { ...state };
      nextState.active = action.content;
      return nextState;
    default:
      return state;
  }
}

export default formReducer;
