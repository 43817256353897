import { combineReducers, createStore } from "redux";

import authReducer from "./reducers/jannu/jannuReducer";
import documentsReducer from "./reducers/annapurna/documentsReducer";
import dropReducer from "./reducers/jannu/dropReducer";
import formsReducer from "./reducers/annapurna/formsReducer";
import funnelGeneral from "./reducers/sangalila/generalReducer";
import gtmReducer from "./reducers/gtmReducer";
import infosFunnel from "./reducers/sangalila/infosFunnelReducer";
import jannuReducer from "./reducers/jannu/jannuReducer";
import loadingAnnapurna from "./reducers/annapurna/loadingAnnapurna";
import modifyFormAnnapurna from "./reducers/annapurna/modifyFormAnnapurna";
import profilAnnapurna from "./reducers/annapurna/profilAnnapurna";
import servicesReducer from "./reducers/annapurna/servicesReducer";

const rootReducer = combineReducers({
  funnelGeneral: funnelGeneral,
  infosFunnel: infosFunnel,
  gtm: gtmReducer,
  forms: formsReducer,
  servicesAnnapurna: servicesReducer,
  documents: documentsReducer,
  loadingAnnapurna: loadingAnnapurna,
  profilAnnapurna: profilAnnapurna,
  modifyFormAnnapurna: modifyFormAnnapurna,
  authJannu: authReducer,
  jannuInfos: jannuReducer,
  dropJannu: dropReducer,
});
const globalStore = createStore(rootReducer);

export default globalStore;
