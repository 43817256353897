/* eslint-disable react/prop-types */

import "../styles/custom.scss";

import * as serviceWorkerRegistration from "../lib/serviceWorkerRegistration";

import React, { useEffect } from "react";

import { NextSeo } from "next-seo";
import { Provider } from "react-redux";
import SSRProvider from "react-bootstrap/SSRProvider";
import Store from "../stores/globalStore";
import { appWithTranslation } from "next-i18next";
import nextI18nextConfig from "../next-i18next.config";
import { setGTM } from "../lib/gtm";
import { useRef } from "react";
import { useRouter } from "next/router";

function MyApp({ session, Component, pageProps }) {
  const isIni = useRef(null);
  const router = useRouter();
  useEffect(() => {
    setGTM();
    serviceWorkerRegistration.register();
    isIni.current = true;
  }, []);

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <React.StrictMode>
      <SSRProvider>
        <Provider store={Store}>
          <NextSeo
            title="Cherpas • Passez aux abonnements intelligents."
            description="Cherpas s'occupe de vos factures et les optimise à vie. Nos membres économisent 219 € en moyenne sur leurs factures."
            openGraph={{
              url: "https://cherpas.com",
              title: "Cherpas • Passez aux abonnements intelligents.",
              description:
                "Cherpas s'occupe de vos factures et les optimise à vie. Nos membres économisent 219 € en moyenne sur leurs factures.",
              images: [
                {
                  url: `${process.env.NEXT_PUBLIC_SITE_URL}/static/img/banner4x3.png`,
                  width: 800,
                  height: 600,
                  alt: "Le logo de Cherpas",
                  type: "image/png",
                },
                {
                  url: `${process.env.NEXT_PUBLIC_SITE_URL}/static/img/banner9x8.png`,
                  width: 900,
                  height: 800,
                  alt: "Le logo de Cherpas",
                  type: "image/png",
                },
                {
                  url: `${process.env.NEXT_PUBLIC_SITE_URL}/static/img/banner9x8.png`,
                },
                {
                  url: `${process.env.NEXT_PUBLIC_SITE_URL}/static/img/banner4x3.png`,
                },
              ],
              siteName: "Cherpas.com",
            }}
            twitter={{
              handle: "@handle",
              site: "@site",
              cardType: "summary_large_image",
            }}
          />
          {getLayout(<Component {...pageProps} session={session} />)}

          {isIni.current &&
            addEventListener("popstate", (event) => {
              try {
                if (event.path[0].location.pathname.includes("espace")) {
                  event.preventDefault();
                  router.push("/mon-espace");
                }
              } catch (error) {
                //
              }
            })}
        </Provider>
      </SSRProvider>
    </React.StrictMode>
  );
}

export default appWithTranslation(MyApp, nextI18nextConfig);
