const isBrowser = typeof window !== "undefined";

const { string } = require("prop-types");
const HttpBackend = require("i18next-http-backend/cjs");
const ChainedBackend = require("i18next-chained-backend").default;
const LocalStorageBackend = require("i18next-localstorage-backend").default;
const path = require("path");

const CleanFrenchTypography = (str) => {
  CleanFrenchTypography.propTypes = {
    str: string,
  };
  return str
    .replaceAll("/ /", "\u00a0")
    .replaceAll("'", "’")
    .replaceAll(" ?", "\u00a0?")
    .replaceAll(" !", "\u00a0!")
    .replaceAll(" »", "\u00a0»")
    .replaceAll("«", "«\u00a0")
    .replaceAll(" :", "\u00a0:")
    .replaceAll(" €", "\u00a0€")
    .replaceAll(" euros", "\u00a0euros");
};

module.exports = {
  backend: {
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 },
      {
        /* loadPath: 'https:// somewhere else' */
      },
    ], // 1 hour
    backends:
      typeof window !== "undefined" ? [LocalStorageBackend, HttpBackend] : [],
  },
  serializeConfig: false,
  i18n: {
    ignoreRoutes: ["/_next/", "/static/", "/public/", "/api/", "/conseils/"],
    defaultLocale: "fr",
    locales: ["fr"],
    localePath: path.resolve("./public/locales"),
    localeDetection: false,
  },
  use: isBrowser
    ? [
        {
          type: "postProcessor",
          name: "cleanFrenchTypography",
          process: function (value) {
            // Of course at some point the langage needs to be taken into account.
            return CleanFrenchTypography(value);
          },
        },
        ChainedBackend,
      ]
    : [],
  postProcess: ["cleanFrenchTypography"],
};

// if you want to configure that use debug: true
