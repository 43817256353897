const initialState = {
  loading: false,
  profile: {},
  documents: [],
  services: {},
  id: undefined,
  currentPk: undefined,
  currentService: undefined,
  service: {},
  listServices: [],
  needDisconnect: false,
};

function jannuReducer(state = initialState, action) {
  const nextState = { ...state };
  switch (action.type) {
    case "startLoadingJannu":
      nextState.loading = true;
      return nextState;
    case "endLoadingJannu":
      nextState.loading = false;
      return nextState;
    case "setJannuProfile":
      nextState.profile = action.content;
      return nextState;
    case "setJannuDocuments":
      nextState.documents = action.content;
      return nextState;
    case "setJannuServices":
      nextState.services = action.content;
      return nextState;
    case "setJannuServicesList":
      nextState.listServices = action.content;
      return nextState;
    case "setJannuId":
      nextState.id = action.content;
      return nextState;
    case "setCurrentPkJannu":
      nextState.currentPk = action.content;
      return nextState;
    case "setCurrentServiceJannu":
      nextState.currentService = action.content;
      return nextState;
    case "setDisconnectJannu":
      nextState.needDisconnect = action.content;
      return nextState;
    default:
      return state;
  }
}

export default jannuReducer;
